import React, { useState } from 'react'
import Layout from '../components/layouts'
import { Footer } from '../components/shareable/footer/Footer'
import { Flex, ImageContain, Text } from '../components/primitives'
import { HeadingMedium } from '../components/shareable/text/HeadingMedium'
import Animation from '../components/shareable/lottie/Animation'
import floor from '../images/floor 2.png'

const WithText = ({ name, text, withFloor }) => {
  const [loading, setLoading] = useState(true)
  return (
    <Flex
      height={'100%'}
      width={['100%', '100%', '50%']}
      jusifyContent={'center'}
      style={{ position: 'relative' }}>
      <Flex width={['300px', '550px', '475px']} jusifyContent={'center'}>
        <Animation
          name={name}
          doneLoading={() => {
            setLoading(false)
          }}
        />
      </Flex>

      {withFloor && !loading && (
        <>
          <ImageContain
            display={['none', 'none', 'initial']}
            width={'100%'}
            style={{ position: 'absolute', top: '199px' }}
            src={floor}
          />
          <ImageContain
            display={['none', 'initial', 'none']}
            width={'100%'}
            style={{ position: 'absolute', top: '230px' }}
            src={floor}
          />
          <ImageContain
            display={['initial', 'none', 'none']}
            width={'100%'}
            style={{ position: 'absolute', top: '127px' }}
            src={floor}
          />
        </>
      )}
      {!loading && (
        <Text
          display={['none', 'initial']}
          width={'100%'}
          textAlign={'center'}
          style={{ position: 'absolute', bottom: 60 }}
          fontSize={[3, 3, 3]}
          color={'dark'}>
          {text}
        </Text>
      )}

      {!loading && (
        <Text
          pr={2}
          pl={2}
          display={['initial', 'none']}
          width={'100%'}
          textAlign={'center'}
          style={{ position: 'absolute', bottom: 20 }}
          fontSize={[3, 3, 3]}
          color={'dark'}>
          {text}
        </Text>
      )}
    </Flex>
  )
}
export default (props) => {
  const isSSR = typeof window === 'undefined'

  const [modalIsOpen, setIsModalOpen] = useState(false)

  return (
    <Layout
      blackNav={true}
      modelOpenProps={modalIsOpen}
      onCloseModal={() => {
        setIsModalOpen(false)
      }}>
      <Flex minHeight={'100vh'} flexDirection={'column'} alignItems={'center'}>
        <HeadingMedium mt={'100px'} mb={6} title={'Så fungerar det'} />
        <Text textAlign={'center'} pl={2} pr={2}>
          Om du väljer ett koncept fungerar det så här:
        </Text>

        <Flex
          jusifyContent={'center'}
          mt={10}
          mb={4}
          maxWidth={'1000px'}
          width={'100%'}
          flexWrap={'wrap'}>
          {!isSSR && (
            <WithText
              name={'h1'}
              text={'1. Du väljer vilket rum som ska få ny inredning'}
              withFloor={true}
            />
          )}

          {!isSSR && (
            <WithText
              name={'h2'}
              text={'2. Filma 360° av rummet'}
              withFloor={false}
            />
          )}

          {!isSSR && (
            <WithText
              name={'h3'}
              text={'3. Välj en stil du gillar'}
              withFloor={true}
            />
          )}

          {!isSSR && (
            <WithText
              name={'h4'}
              text={
                '4. En av våra inredare tar fram ett koncept tillsammans med dig'
              }
              withFloor={true}
            />
          )}
          {!isSSR && (
            <WithText
              name={'h5'}
              text={
                '5. Inte helt hundra? Inga problem! Inredaren kan ändra förslaget en vända enligt dina önskemål'
              }
              withFloor={false}
            />
          )}
        </Flex>
      </Flex>
      <Footer
        actionClick={() => {
          setIsModalOpen(true)
        }}
      />
    </Layout>
  )
}
