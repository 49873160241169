import React, { useEffect, useState } from 'react'
import Loadable from '@loadable/component'
import axios from 'axios'
import { getUrl } from '../../../helpers/getUrl'
import { Flex } from '../../primitives'

import { LoadingSpinner } from './LoadingSpinner'
const Lottie = Loadable(() => import('react-lottie'))

const timeout = (ms) => new Promise((res) => setTimeout(res, ms))

const Animation = ({ name, doneLoading }) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(null)

  useEffect(() => {
    async function fetchMyAPI() {
      setLoading(true)
      // try with more?
      await timeout(2500) // if not bad scroe in pagespeed.
      // the animation lottie files are to big to process.
      let url = getUrl() + '/getLottieFiles?name=' + name
      const result = await axios.get(url)
      setData(result.data)
      setLoading(false)
      doneLoading()
    }

    fetchMyAPI()
    // eslint-disable-next-line
  }, [])

  if (loading || data === null) {
    return (
      <Flex width={'100%'} jusifyContent={'center'}>
        <Flex height={'40px'} width={'50px'}>
          <LoadingSpinner />
        </Flex>{' '}
      </Flex>
    )
  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: data,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

  return <Lottie options={defaultOptions} />
}

export default Animation
