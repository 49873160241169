export const getUrl = () => {
  if (window.location.origin === 'https://stage.hemset.se') {
    return 'https://adminstage.hemset.se/api'
  }

  if (process.env.NODE_ENV !== 'production') {
    return 'http://localhost:4000'
  }

  return 'https://admin.hemset.se/api'
}
